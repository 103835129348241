<template>
  <!-- 打印机详情 -->
  <div>
    <div class="a-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="prePath + 'printer'"
          >打印机列表</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{ printerInfo.printerName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div>
      <el-descriptions
        title="打印机详情"
        border
        v-loading="loading.info"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <template slot="extra">
          <el-button size="mini" @click="handleBindCode" type="primary"
            >生成绑定码</el-button
          >
          <template v-if="printerInfo.state === 'Disable'">
            <el-button size="mini" @click="handleEnable" type="success"
              >启用</el-button
            >
          </template>
          <template v-if="printerInfo.state === 'Free'">
            <el-button size="mini" @click="handleDisable" type="danger"
              >禁用</el-button
            >
          </template>
          <el-popover
            title="修改打印机名称"
            placement="top"
            width="200"
            trigger="click"
          >
            <template>
              <el-input placeholder="请输入打印机名称" v-model="printerName">
              </el-input>
              <el-button
                style="margin-top: 1rem"
                size="mini"
                @click="handleModifyName"
                type="primary"
                >提交</el-button
              >
            </template>
            <el-button
              style="margin-left: 1rem"
              size="mini"
              slot="reference"
              type="primary"
              >修改名称</el-button
            >
          </el-popover>
        </template>
        <el-descriptions-item label="编号">
          {{ printerInfo.printerNo }}</el-descriptions-item
        >
        <el-descriptions-item label="名称">
          {{ printerInfo.printerName }}</el-descriptions-item
        >
        <el-descriptions-item label="状态">
          <el-tag size="small" :type="types[printerInfo.state]">{{
            printerInfo.state ? status[printerInfo.state] : ""
          }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="工艺">
          {{ printerInfo.technology }}</el-descriptions-item
        >
        <el-descriptions-item label="绑定码">
          <span style="font-size: 2rem; font-weight: 700">{{
            code
          }}</span></el-descriptions-item
        >
      </el-descriptions>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      printerId: this.$route.params.printerId,
      printerNo: JSON.parse(localStorage.getItem("printer")).printerNo,
      prePath: this.$route.path.split("printer")[0],
      printerInfo: {},
      printerName: undefined,
      manufacturerId: localStorage.getItem("serviceId"),
      code: "****",
      status: this.$store.state.status,
      types: this.$store.state.types,
      loading: {
        info: true,
      },
    };
  },
  methods: {
    /**获取打印机信息请求 */
    getPrinterInfo() {
      const printerId = this.printerId;
      if (printerId) {
        this.$axios.getPrinterInfo({ printerId }).then((res) => {
          if (!res.error) {
            this.printerInfo = res;
            this.printerName = res.printerName;
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
          this.loading.info = false;
        });
      }
    },
    /**生成绑定码 */
    handleBindCode() {
      const printerId = this.printerId;
      if (printerId) {
        this.$axios.getBindCode({ printerId }).then((res) => {
          if (!res.error) {
            this.code = res;
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      }
    },
    /**禁用 */
    handleDisable() {
      const printerId = this.printerId;
      if (printerId) {
        this.$axios.disablePrinter({ printerId }).then((res) => {
          if (!res.error) {
            this.$message({
              message: "禁用打印机成功",
              type: "success",
            });
            this.getPrinterInfo();
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      }
    },
    /**启用 */
    handleEnable() {
      const printerId = this.printerId;
      if (printerId) {
        this.$axios.enablePrinter({ printerId }).then((res) => {
          if (!res.error) {
            this.$message({
              message: "启用打印机成功",
              type: "success",
            });
            this.getPrinterInfo();
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      }
    },
    /**修改打印机名称 */
    handleModifyName() {
      const printerId = this.printerId;
      const manufacturerId = this.manufacturerId;
      let printerName = this.printerName;

      if (printerId) {
        this.$axios
          .modifyPrinterName({ printerId, manufacturerId, printerName })
          .then((res) => {
            if (!res.error) {
              this.$message({
                message: "打印机名称修改成功",
                type: "success",
              });
              this.getPrinterInfo();
            } else {
              this.$notify.error({
                title: "提示",
                message: res.error,
                duration: 2000,
              });
            }
          });
      }
    },
  },
  mounted() {
    this.getPrinterInfo();
  },
};
</script>

<style lang="scss" scoped>
.a-detail-item {
  margin: 0.5rem 0;
}
.a-detail-operate {
  margin-top: 2rem;
  text-align: center;
}
.a-detail-code {
  &::before {
    content: "";
    display: block;
    width: 100%;
    border-top: 1px solid #aaa;
    margin: 1rem 0;
  }
  font-size: 1.2rem;
}
</style>